<template>
  <models :showmodel="showeditename"
          :title="title"
          :canceltext="canceltext"
          :confirmtext="confirmtext"
          :needcancel="needcancel"
          @confirm="confirmedit"
          @closemodel="closeeditename"
          class="ifeditename">
    <div class="maincontent">
      <el-row>
        <el-col :span="6">
          <div class="labelname"><span>加速线路名称：</span></div>
        </el-col>
        <el-col :span="18">
          <el-input size="small"
                    v-model="Name"
                    clearable></el-input>
        </el-col>
      </el-row>
    </div>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
export default {
  props: {
    showeditename: {
      type: Boolean,
    },
    row: {
      type: Object,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
      default: "取消",
    },
    confirmtext: {
      type: String,
      default: "确定",
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      Name: this.row.name,
    };
  },
  watch: {
    row: function (val) {
      this.Name = val.name;
      this.row = val;
    },
  },
  components: {
    Models,
  },
  methods: {
    confirmedit () {
      if(!this.Name) {
        this.$message.error("名称不能为空"); 
        return
      }
      let data = {
        id: this.row.id,
        name: this.Name,
      };
      this.$store.dispatch("line/changelinename", { ...data }).then((res) => {
        if (res.retCode == 0) {
          this.$message.success("修改成功");
          this.closeeditename();
        } else {
          this.$message.error("修改失败");
          this.closeeditename();
        }
      });
    },
    closeeditename () {
      this.$emit("closeeditename");
    },
  },
};
</script>
<style lang="scss" scoped>
.ifeditename {
  ::v-deep .el-dialog {
    width: 450px;
    height: 250px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 80px;
  }
  ::v-deep .el-table::before {
    background-color: #fff;
  }
  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .maincontent {
    margin-top: 20px;
  }
  .warningtitle {
    height: 40px;
    margin-bottom: 20px;
    background-color: rgba(228, 229, 241);
    line-height: 40px;
    color: rgb(102, 102, 102);
    font-size: 14px;
    .warningcontent {
      margin-left: 20px;
    }
  }
}
</style>