<template>
  <models :showmodel="showUpgrade" :title="title" :canceltext="canceltext" :confirmtext="confirmtext"
    :needcancel="needcancel" @confirm="confirmedit" @closemodel="closeUpgrade" class="ifeditename">
    <div class="maincontent">
      <el-row>
        <el-col :span="6">
          <div class="labelname"><span>当前规格：</span></div>
        </el-col>
        <el-col :span="18">
          <div class="cur-product">{{ ProductTypeMap[row.productId] }}</div>
        </el-col>
      </el-row>
      <el-row class="row2">
        <el-col :span="6">
          <div class="labelname"><span>升级规格：</span></div>
        </el-col>
        <el-col :span="18">
          <el-select size="small" v-model="upgradeProduct" filterable placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-radio-group v-model="payType" class="payradio">
        <el-radio class="itemradio" label="amount">余额支付(可用:{{ balance }})
        </el-radio>
        <el-radio class="itemradio" label="wechatpay_native">
          <i class="iconfont icon-weixinzhifu"></i>微信支付
        </el-radio>
        <el-radio class="itemradio" label="alipay_page">
          <div class="itemradio-content">
            <img class="icon-alipay" src="@/assets/icon-alipay.png" />支付宝支付
          </div>
        </el-radio>
      </el-radio-group>
    </div>
  </models>
</template>
<script>
import { ProductTypeMap } from "@/utils/constants";
import Models from "../../../components/Models/index.vue";
import navigation from "@/utils/navigation";
export default {
  props: {
    showUpgrade: {
      type: Boolean,
    },
    avaliableUpgradeMap: {
      type: Map,
    },
    row: {
      type: Object,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
      default: "取消",
    },
    confirmtext: {
      type: String,
      default: "确定",
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      upgradeProduct: null,
      options: [],
      ProductTypeMap: ProductTypeMap,
      balance: 0,
      payType: 'amount',
      tradeNo: '',
      totalAmount: 0,
    };
  },
  watch: {
    row: function (val) {
      this.upgradeProduct = null;
      this.options = [];
      for (let p of this.avaliableUpgradeMap.get(val.productId)) {
        this.options.push({ value: p, label: ProductTypeMap[p] })
      }
      this.getUserBalance()
    },
  },
  components: {
    Models,
  },
  methods: {
    confirmedit () {
      if (!this.upgradeProduct) {
        this.$message.error("请选择升级规格");
        return
      }
      if (this.totalAmount > 0 && this.tradeNo != '') {
        this.goPay()
      } else {
        this.$store.dispatch("pay/productUpgrade", { ResourceType: "PATH", ResourceId: this.row.id, ProductAfter: this.upgradeProduct }).then((res) => {
          if (res.RetCode == 0) {
            this.totalAmount = res.TotalAmount
            this.tradeNo = res.TradeNo
            this.goPay()
          } else {
            this.$message.error(res.Message || "升级失败");
          }
        });
      }
    },
    goPay () {
      if (this.totalAmount > this.balance * 100 && this.payType == 'amount') {
        this.$message.error("可使用的余额不足")
        return
      }
      navigation.navigateTo({
        url: "upgradeorderconfirm",
        params: {
          tradeNo: this.tradeNo,
          totalAmount: this.totalAmount,
          payType: this.payType,
          productBefore: this.row.productId,
          productAfter: this.upgradeProduct,
          resourceId: this.row.id,
        },
      });
      this.closeUpgrade()
    },
    closeUpgrade () {
      this.$emit("closeUpgrade");
    },
    getUserBalance () {
      this.$store.dispatch("user/getuserwallet").then((res) => {
        // console.log("user wallet: " + JSON.stringify(res));
        if (res.RetCode == 0) {
          this.balance = res.Balance / 100;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ifeditename {
  ::v-deep .el-dialog {
    width: 450px;
    height: 350px;
  }

  ::v-deep .el-dialog__header {
    padding: 0;
  }

  ::v-deep .el-dialog__footer {
    padding: 0;
  }

  ::v-deep .el-dialog__body {
    height: 180px;
  }

  ::v-deep .el-table::before {
    background-color: #fff;
  }

  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }

  .cur-product {
    min-height: 32px;
    line-height: 32px;
  }

  .maincontent {
    margin-top: 20px;
  }

  .warningtitle {
    height: 40px;
    margin-bottom: 20px;
    background-color: rgba(228, 229, 241);
    line-height: 40px;
    color: rgb(102, 102, 102);
    font-size: 14px;

    .warningcontent {
      margin-left: 20px;
    }
  }

  .row2 {
    margin: 10px 0;
  }

  .payradio {
    margin-top: 8px;
    margin-left: 16px;

    .icon-weixinzhifu {
      font-size: 22px;
      color: rgb(17, 169, 17);
      margin-right: 5px;
    }

    .itemradio {
      display: flex;
      margin-bottom: 4px;
      align-items: center;
      line-height: normal;

      .itemradio-content {
        width: 100px;
        display: flex;
        align-items: center;
      }

      .icon-weixinzhifu {
        font-size: 17px;
      }

      .icon-alipay {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
  }
}
</style>