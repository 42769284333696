<template>
  <models
    :showmodel="showsettinglist"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @confirm="confirmedit"
    @closemodel="closesettinglist"
    class="ifeditename"
  >
     <!-- <div>
         <span>小提示：重新登录或更换浏览器，自定义列表消失</span>
     </div> -->
     <br/>
    <!-- <el-divider></el-divider> -->
     <div>
        <el-checkbox-group v-model="realList" class="check-list">
          <el-checkbox style="width: 85px ; margin-bottom:35px" v-for="item in dataArr" :key="item.label" :label="item.label" :disabled="item.label=='资源ID' || item.label=='名称' || item.label=='规格' || item.label=='加速区域' || item.label=='IP地址' || item.label=='状态' || item.label=='到期时间' || item.label=='业务组' || item.label=='自动续费'">{{ item.label }}</el-checkbox>
        </el-checkbox-group>
      </div>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
export default {
  props: {
    showsettinglist: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
      default: "取消",
    },
    confirmtext: {
      type: String,
      default: "确定",
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
    checkList: {
      type: Array,
      default: () => []
    },
    defaultArr: {
      type: Array,
      default: () => []
    },
    dataArr: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      realList: [],
    };
  },
   watch: {
    checkList(val) {
      this.realList = val
      console.log("fffff",this.realList)
    }
  },
  components: {
    Models,
  },
  methods: {
    // 点击确定
    confirmedit() {
      this.$emit('confirmedit', this.realList)
      this.$emit("closesettinglist");
    },
    closesettinglist() {
      this.realList = this.checkList
      this.$emit("closesettinglist");
    },
  },
};
</script>
<style lang="scss" scoped>
.ifeditename {
  ::v-deep .el-dialog {
    width: 500px;
    height: 350px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    padding-top: 15px;
    height: 180px;
  }
  ::v-deep .el-table::before {
    background-color: #fff;
  }
  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .maincontent {
    margin-top: 20px;
  }
  .warningtitle {
    height: 40px;
    margin-bottom: 20px;
    background-color: rgba(228, 229, 241);
    line-height: 40px;
    color: rgb(102, 102, 102);
    font-size: 14px;
    .warningcontent {
      margin-left: 20px;
    }
  }
}
</style>