<template>
  <el-dialog class="isdownload"
             :visible.sync="isqrcodeshow"
             @open="open"
             :before-close="closemodel">
    <div class="qrcode-center"
         v-if="isqrcodeshow">
      <div class="qrcode-wrap">
        <div class="qrcode"
             id="qrcodeImg"></div>
      </div>
    </div>
    <div class="qrcode-region">地域: {{ region }}</div>
    <div class="qrcode-ip">IP: {{ ip }}</div>
    <div class="qrcode-text" v-if="receivedValue">注意: 试用直播快初体验将获得三天试用权</div>
  </el-dialog>
</template>
<script>
import { IpRegionMap } from "@/utils/constants";
import QRCode from "qrcodejs2";

export default {
  name: "QRCodeModel",
  props: ["isqrcodeshow", "token"],
  data () {
    return {
      ip: '',
      region: '',
      receivedValue: false
    };
  },
  methods: {
    creatQrCode (token) {
      console.log("create qrcode " + token);
      this.$nextTick(function () {
        //setTimeout(() => {
        var qrcode = new QRCode("qrcodeImg", {
          text: token,
          width: 280,
          height: 280,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
        //}, 1000);
      });
    },
    open () { },
    closemodel () {
      this.$emit("closedownloadmodel");
    },
    showQrCodeView (data, ip, region) {
      this.$store
        .dispatch("line/getpathtoken", { id: data })
        .then((res) => {
          console.log("get token response:" + JSON.stringify(res));
          if (res.token !== "") {
            this.creatQrCode(res.token);
            this.ip = ip
            this.region = IpRegionMap[region] || region
          }
        });
    },
    fetchAuthority(id) {
      this.$store.dispatch("line/isprobation", {id: id}).then((res) => {
        const authority = res.authority; // 从响应中提取权限信息
        this.receivedValue = authority; // 将权限信息赋值给组件的本地状态
      }).catch((err) => {
        console.error("获取白名单权限信息时出错:", err);
     });
    },
  },
};
</script>
<style scoped lang="scss">
.isdownload {
  ::v-deep .el-dialog {
    width: 320px;
  }
  ::v-deep .el-dialog__body {
    // height: 360px;
    padding: 0 0 10px 0;
    overflow: hidden;
  }
  .qrcode-center {
    width: 300px;
    height: 300px;
    position: relative;

    .qrcode-wrap {
      background: #fff;
      width: 250px;
      height: 250px;
      padding: 10px;
      box-sizing: border-box;
      position: absolute;
      top: 10px;
      left: 10px;
      right: 0;
      //margin: auto;
    }

    .qrcode {
      width: 100%;
      height: 100%;
      background: #fff;
    }
  }

  .qrcode-region {
    margin-top: 16px;
    font-size: 18px;
    text-align: center; 
  }
  .qrcode-ip {
    font-size: 18px;
    text-align: center;
    margin-top: 4px;
  }
  .qrcode-text {
    font-size: 12px;
    text-align: center;
    margin-top: 4px;
  }
}
</style>