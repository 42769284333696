export function groupBy(list = [], keyFn) {
    const map = {}
    list.forEach(li => {
        const key = keyFn(li)
        if (map.hasOwnProperty(key)) {
            map[key].push(li)
        } else {
            map[key] = [li]
        }
    })
    return map
}