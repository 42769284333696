<template>
  <models
    :showmodel="showeditegroup"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @confirm="confirmedit"
    @closemodel="closeeditegroup"
    class="ifeditegroup"
    @open="open"
  >
    <div class="maincontent">
      <el-row>
        <el-col :span="6">
          <div class="labelname"><span>分组：</span></div>
        </el-col>
        <el-col :span="18">
          <el-select
            size="small"
            v-model="GroupId"
            filterable
            placeholder="请选择分组"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
export default {
  props: {
    showeditegroup: {
      type: Boolean,
    },
    row: {
      type: Object,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
      default: "取消",
    },
    confirmtext: {
      type: String,
      default: "确定",
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: [],
      GroupId: this.row.GroupId,
    };
  },
  watch: {
    row: function (val) {
      this.GroupId = val.GroupId;
      this.row = val;
    },
  },
  components: {
    Models,
  },
  methods: {
    confirmedit() {
      let data = {
        lineIds: [this.row.id],
        groupId: this.GroupId,
      };
      console.log("line change group: " + JSON.stringify(data));
      this.$store
        .dispatch("line/changeGroup", { ...data })
        .then((res) => {
          if (res.retCode == 0) {
            this.$message.success("修改成功");
            this.closeeditegroup();
          } else {
            this.$message.error("修改失败");
            this.closeeditegroup();
          }
        });
    },
    closeeditegroup() {
      this.options = [];
      this.$emit("closeeditegroup");
    },
    open() {
      this.getgrouplist();
    },
    getgrouplist() {
      this.$store.dispatch("group/getgrouplist").then((res) => {
        if (res.RetCode == 0) {
          res.Groups.length &&
            res.Groups.forEach((item, index) => {
              let data = {
                label: item.Name,
                value: item.Id,
              };
              this.options.push(data);
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ifeditegroup {
  ::v-deep .el-dialog {
    width: 450px;
    height: 250px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 80px;
  }
  ::v-deep .el-table::before {
    background-color: #fff;
  }
  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .maincontent {
    margin-top: 20px;
  }
  .warningtitle {
    height: 40px;
    margin-bottom: 20px;
    background-color: rgba(228, 229, 241);
    line-height: 40px;
    color: rgb(102, 102, 102);
    font-size: 14px;
    .warningcontent {
      margin-left: 20px;
    }
  }
}
</style>